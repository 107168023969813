section {
  &.teaser {

    .row {
      //@extend .align-items-center;
    }

    .articles, .thats-me {
      @extend .col;

      @media (max-width: 700px) {
        flex-basis: 100%;
      }
    }


    .thats-me {
      .info {

        position: relative;
        display: block;

        img {
          @extend .img-fluid
        }

        > div {
          position: absolute;
          bottom: .55rem;
          left: .55rem;
          right: .55rem;

          text-decoration: none;

          a {
            text-decoration: none;
          }

          p, span {
            background-color: #fff;

            transition: all 0.2s linear;

            padding: .5rem .7rem;
          }

          span {
            @extend h3;

            font-weight: 900;

            display: block;

          }

          p {
            color: $text-color;
            font-weight: 300;

            border-top: 3px solid #fff;

            a {
              color: $text-color;
            }
          }


        }

        &:hover {

          > div {
            span {
              background-color: $brand-primary;
              color: #fff;
            }

            p {
              border-top: 3px solid $brand-primary;
            }
          }
        }
      }
    }
  }

  &.slogan {
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;

    p {
      font-size: 38px;
      font-weight: bold;
      display: inline-block;
      padding-right: 60px;

      color: rgba($text-color, 0.5);

      @media (min-width: 400px) {
        font-size: 45px;
      }

      @media (min-width: 650px) {
        font-size: 60px;
      }
    }
  }
}
