article {
  &.teaser {

    position: relative;
    display: block;

    margin-bottom: 2rem;

    a {
      &.img {
        position: relative;
        display: block;

        img {
          @extend .img-fluid
        }

        &:after {
          width: 100%;
          height: 100%;

          position: absolute;

          left: 0;
          top: 0;

          background-color: transparent;

          transition: all 0.8s linear;

          content: ' ';


        }
      }
    }

    h3, h2 {
      a, span {
        position: absolute;
        bottom: .55rem;
        left: .55rem;

        text-decoration: none;

        font-weight: 900;

        top: auto;
        right: auto;

        background-color: #fff;

        transition: all 0.2s linear;

        padding: .5rem .7rem;
      }
    }

    &:hover {

      a {
        &.img {
          &:after {
            background-color: rgba(#000, 0.35);
          }
        }
      }

      h3, h2 {
        a, span {

          background-color: $brand-primary;
          color: #fff;
        }
      }
    }
  }
}