section {
  &.highlights {

    margin-top: -290px;

    margin-bottom: 5.5rem;

    h1 {
      display: block;
      margin-bottom: 130px;
      font-weight: 900;
      text-align: center;
      color: #fff;

      @media (max-width: 768px) {
        font-size: 2rem;
      }

      @media (max-width: 410px) {
        display: none;
      }
    }

    .row {

      @media (max-width: 410px) {
        margin-top: 200px;
      }

      .highlight {
        @extend .col;
        background-color: #fff;

        border-top: 3px solid $brand-primary;

        position: relative;

        box-shadow: -4px -12px 16px rgba(0, 0, 0, 0.2);

        .icon {
          display: block;
          width: 100%;
          text-align: center;
          padding: 1.5rem 0 .5rem 0;

          color: $brand-primary;
          font-size: 3.5rem;

          .img-svg {
            height: 80px;
            max-width: 100%;
            width: auto;

            path {
              fill: $brand-primary !important;
            }
          }
        }

        h2 {
          text-align: center;
          font-weight: 900;
        }

        p {
          text-align: center;
          font-size: 90%;

          padding: 0.25rem 0.55rem;

          color: $text-color-light;
        }

        &:nth-child(2) {
          margin-top: -70px;

          z-index: 2;

          box-shadow: 0px -12px 16px rgba(0, 0, 0, 0.2);

          @media (max-width: 506px) {
            margin-top: 0;
          }
        }

        &:nth-child(3) {
          box-shadow: 4px -12px 16px rgba(0, 0, 0, 0.2);
        }

      }
    }
  }
}