section {
  &.contact-box {
    width: 100%;

    background-color: $light-gray;

    padding: 3.5rem 0;

    margin-bottom: 5.5rem;

    position: relative;

    &:before {
      content: ' ';

      position: absolute;
      left: 0;
      top: 0;

      width: 100%;
      height: 100%;

      background-image: url('/img/home/header_1.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      opacity: 0.1;

    }

    .contact {
      .list {
        @extend .row;

        a {
          @extend .col;
          background-color: #fff;
          display: inline-block;

          text-decoration: none;

          padding: 1.9375rem;

          transition: all 0.3s linear;

          &:first-child {
            margin-right: 0.5rem;
          }

          &:nth-child(2) {
            margin-left: 0.5rem;
          }

          span {
            font-weight: 900;
            font-size: 25px;
          }

          small {
            color: $text-color;
          }

          span, small {
            display: block;
            width: 100%;

            text-align: center;


            transition: all 0.3s linear;
          }

          &:hover {
            background-color: $brand-primary;

            transform: scale(0.9);

            span, small {
              color: #fff;
            }
          }
        }

        span {
          &.info {
            width: 100%;
            background-color: #fff;
            text-align: center;

            display: inline-block;

            margin-top: 1rem;

            padding: 0.7rem;

            border-top: 3px solid $brand-primary;

            small {
              display: block;
              width: 100%;
            }
          }
        }
      }

    }
  }
}