.service {
    .img-title {
        padding: 5px;
        border: 1px solid $brand-primary-dark;
        border-radius: 3px;
        margin-bottom: 20px;

        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
    }
}
