.row-about {
    .infos {
        li, span {
            color: $brand-primary;
            font-weight: 600;
        }

        .teaser {
            color: $brand-info;
            font-size: 60px;
            font-weight: 900;
            opacity: 0.3;
            margin-bottom: 20px;
        }
    }

    dl {
        font-size: 150%;
    }

    dt {
        font-size: 90%;
        font-weight: 900;

        &.small {
            font-size: 70%;
        }

        span {
            color: $brand-info;
        }
    }

    dd {
        font-size: 100%;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba($brand-info, 0.5);

        margin-left: 10px;


        &.small {
            font-size: 80%;
        }

        span {
            display: block;
            font-size: 70%;
            color: $brand-primary;
            margin-left: 5px;
        }
    }
}
