footer {

  .col {
    @media (max-width: 700px) {
      flex-basis: 100%;
    }
  }

  .container {
    @media (max-width: 700px) {
      padding-left: 0;
    }
  }

  .footer-top {

    margin-top: 3rem;

    background-color: #484a4c;
    padding: 0.5rem 1rem;

    box-shadow:inset 0px -4px 10px rgba(0, 0, 0, 0.1);

    @media (max-width: 700px) {
      flex-basis: 100%;
    }

    ul {
      margin: 0;
      padding: 0;

      display: inline-block;
      width: 100%;

      &.left {
        text-align: left;
      }

      &.right {
        text-align: right;
      }

      li {
        padding: 0;
        margin: 0;

        display: inline-block;

        &:not(:last-child) {
          padding-right: 0.75rem;
          margin-right: 0.75rem;

          border-right: 2px solid rgba(255, 255, 255, 0.15);
        }

        a {
          color: #fff;
          text-decoration: none;

          &:hover {
            color: rgba(#fff, 0.8);
            text-decoration: none;
          }
        }
      }
    }

  }

  .footer-bottom {
    background-color: darken(#34373c, 1);

    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.15);
    padding: 2rem 0rem;

    color: #fff;

    a {
      color: #fff;
      text-decoration: none;
    }

    ul {
      margin: 0;
      padding: 0;

      list-style: none;

      text-align: center;

      li {
        a {
          display: block;

          position: relative;

          margin-bottom: 0.55rem;

          &:after {
            width: 70%;
            height: 2px;

            background-color: rgba(#fff, 0.2);

            content: ' ';

            position: absolute;
            bottom: -1px;
            left: 15%;

            transition: all 0.5s linear;
          }

          &:hover {

            &:after {
              background-color: rgba($brand-primary, 0.8);
            }
          }
        }
      }

      &.person {

        li {

          color: #9c9c9c;

          &.name {
            @extend h5;

            color: $brand-primary;
            position: relative;

            padding-bottom: 0.25rem;

            &:after {
              width: 70%;
              height: 2px;

              background-color: rgba($brand-primary, 0.8);

              content: ' ';

              position: absolute;
              bottom: -1px;
              left: 15%;

              transition: all 0.5s linear;
            }
          }
        }
      }
    }
  }
}