header {
  &.main-header {
    background-image: url('/img/home/header_1.jpg');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
    max-height: 500px;
    min-height: 350px;
    height: 90vw;

    position: relative;

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      background-color: rgba($brand-primary-dark, 0.4);

      width: 100%;
      height: 100%;

      content: ' ';
    }
  }
}