.headline {
  display: table;
  margin: auto;

  padding-bottom: 0.5rem;

  margin-bottom: 3rem;
  margin-top: 1rem;

  position: relative;

  &:after {
    width: 70%;
    height: 3px;

    background-color: $brand-primary;

    content: ' ';

    position: absolute;
    bottom: -1px;
    left: 15%;
  }
}