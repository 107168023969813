nav {
  &.navbar {
    &.navbar-light {
      padding: 0;

      background-color: rgba($navbar-background, 0.95) !important;

      flex-direction: column;

      .navbar-brand {

        padding-top: 1rem;
        padding-bottom: 1rem;

        .brand-img {
          @extend .img-fluid;

          max-width: 200px;
        }
      }

      .collapse {
        ul {
          margin-left: auto;
          margin-right: 0px !important;
        }
      }

      .navbar-topbar {
        width: 100%;
        background-color: #2e3436;
        padding: 0.25rem;

        color: #fff;

        ul {
          margin: 0;
          padding: 0;

          display: inline-block;
          width: 100%;

          &.left {
            text-align: left;


            @media (max-width: 410px) {
              li {
                border: 0 !important;
                &:not(:first-child) {
                  display: none;
                }
              }
            }
          }

          &.right {
            text-align: right;

            @media (max-width: 410px) {
              li {
                &:first-child {
                  display: none;
                }
              }
            }
          }

          li {
            padding: 0;
            margin: 0;

            display: inline-block;

            &:not(:last-child) {
              padding-right: 0.75rem;
              margin-right: 0.75rem;

              border-right: 2px solid rgba(255, 255, 255, 0.35);
            }

            a {
              color: #fff;
              text-decoration: none;

              &:hover {
                color: rgba(#fff, 0.8);
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}